import React, { createContext, useReducer } from "react";

const FormContext = createContext();
const { Provider } = FormContext;

const initialFirmenfahrzeug = {
  tankbeleg: null,
  betrag: "",
};

const initialPrivatfahrzeug = {
  datum: null,
  startOrt: "",
  endOrt: "",
  km: "",
};

const initialTaxi = {
  art: "",
  datum: null,
  startOrt: "",
  endOrt: "",
  belege: "",
  betrag: "",
};

const initialVerpflegungsmehraufwand = {
  verpflegungdatum: null,
  Frühstück: null,
  Mittagessen: null,
  Abendessen: null,
};

const initialUbernachtungskosten = {
  checkIn: null,
  checkOut: null,
  kosten: "",
  Verpflegung: "",
  belege: null,
};

const initialReisenebenkosten = { art: "", kosten: "", belege: "" };

const initialAbschlussbescheinigung = {
  KooperationspartnerderPT: "",
  UmfangPT: "",
  StartdatumPT: null,
  EnddatumPT: null,
};

const initialState = {
  step1: null,
  step2: null,
  step3: null,
  step4: null,
  stepall: null,
  individuelleTeilnhme: [
    {
      Veranstaltungstitel: "",
      Startdatum: null,
      Enddatum: null,
      UE: "",
      Art: null,
      Punkte: "",
      VID: "",
      AKNR: "",
      Betreuerin: "",
    },
  ],
  controlledValues: {
    Startdatum: null,
    Enddatum: null,
    Anrede: null,
    Emailadresse: null,
    Fachpsychotherapeut: null,
    Geburtsdatum: null,
    Ansprache: null,
    Art: null,
    AusfallsBehandlungsdatum: null,
    iban: null,
    iagree: false,
    Beginn: null,
    Ende: null,
    Erstattungsart: null,
    Reisetyp: null,
    trainingCompleted: false,
    gotCredit: false,
    Abgabedatum: null,
    ErhoehterVerpflegungsaufwand: false,
  },
  uploadedAnlagen: [],
  Reisekosten: {
    Firmenfahrzeug: [],
    Privatfahrzeug: [],
    taxi: [],
    Verpflegungsmehraufwand: [initialVerpflegungsmehraufwand],
    Ubernachtungskosten: [],
    Reisenebenkosten: [],
  },
  Abschlussbescheinigung: {
    PraktischeTaetigkeit: [initialAbschlussbescheinigung],
    PraktischeTaetigkeit2: [initialAbschlussbescheinigung],
  },
};

const formReducer = (state = initialState, action) => {
  switch (action?.type) {
    case "STEP1":
      return {
        ...state,
        step1: action?.payload?.step1,
      };
    case "STEP2":
      return {
        ...state,
        step2: action?.payload?.step2,
      };
    case "STEP3":
      return {
        ...state,
        step3: action?.payload?.step3,
      };
    case "STEP4":
      return {
        ...state,
        step4: action?.payload?.step4,
      };
    case "STEPALL":
      let mergeSteps = {
        ...state?.step2,
        ...state?.step3,
        ...state?.step4,
      };
      let step1 = { step1: state?.step1 };
      return {
        ...state,
        stepall: { ...step1, ...mergeSteps },
      };
    case "INDIVIDUELLE_TEILNAHME": {
      return {
        ...state,
        individuelleTeilnhme: action?.payload?.individuelleTeilnhme || initialState.individuelleTeilnhme,
      };
    }
    case "CONTROLLED_VALUES": {
      return {
        ...state,
        controlledValues: action?.payload?.controlledValues || initialState.controlledValues,
      };
    }
    case "UPLOADED_ANLAGEN": {
      return {
        ...state,
        uploadedAnlagen: action?.payload?.uploadedAnlagen || initialState.uploadedAnlagen,
      };
    }
    case "UPDATE_REISEKOSTEN": {
      return {
        ...state,
        Reisekosten: action?.payload?.Reisekosten || initialState.Reisekosten,
      };
    }
    case "CLEAR_REISEKOSTEN": {
      return {
        ...state,
        Reisekosten: initialState.Reisekosten,
      };
    }
    case "UPDATE_ABSCHLUSSBESCHEINIGUNG": {
      return {
        ...state,
        Abschlussbescheinigung: action?.payload?.Abschlussbescheinigung || initialState.Abschlussbescheinigung,
      };
    }
    case "CLEAR_ABSCHLUSSBESCHEINIGUNG": {
      return {
        ...state,
        Abschlussbescheinigung: initialState.Abschlussbescheinigung,
      };
    }
    default:
      return state;
  }
};

const FormProvider = ({ children }) => {
  var [state, dispatch] = useReducer(formReducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export {
  FormProvider,
  FormContext,
  initialFirmenfahrzeug,
  initialPrivatfahrzeug,
  initialTaxi,
  initialUbernachtungskosten,
  initialReisenebenkosten,
  initialAbschlussbescheinigung,
};
